<template>
  <b-card>
    <b-card-body style="padding: 0rem">
      <div class="">
        <div>
          <div class="title text-muted">
            <span>Mes notes</span>
          </div>
          <div class="col-md-2 mt-2 shadow p-1 mb-2 bg-body rounded">
            <span>Moyenne : </span>
          </div>
          
          <div class="row ml-1 mb-2">
            <b-form-group
              label=""
              label-cols-sm="0"
              label-align-sm="left"
              label-size=""
              label-for="filterInput"
              class="mb-2 ml-auto mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher..."
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- Table beginning -->
    <b-table
      no-border-collapse
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(Popularity)="data">
        <b-progress
          :value="data.value.value"
          max="100"
          height="7px"
          :variant="data.value.variant"
          striped
          class="alert-info shadow-sm rounded"
        />
      </template>

      <template #cell(Action)>
        <b-link href="/discussion"
          ><feather-icon icon="MessageSquareIcon" />
        </b-link>
      </template>

      <template #cell(note_projet)="data">
        <b-form-rating
          readonly
          v-model="data.value.note_projet"
          no-border
          style="background-color: white"
          class="text-warning"
        ></b-form-rating>
      </template>
      

      <template #cell(note_tache)="data">
        <b-form-rating
          readonly
          v-model="data.value.note_tache"
          no-border
          style="background-color: white"
          class="text-warning"
        ></b-form-rating>
      </template>

      <template #cell="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Affichage par page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import { BProgress } from "bootstrap-vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BProgress,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "Date",
        "Tâche",
        { key: "note_projet", label: "Note de projet" },
        { key: "note_tache", label: "Note de la tâche" },
        "Superviseur",
        "Observation",
      ],
      /* eslint-disable global-require */

      items: [
        {
          Date: "12123",
          Tâche: "Serge AHOUANSINOU",
          note_projet: { note_projet: 5 },
          note_tache: { note_tache: 2 },
          Superviseur: "Analyste programmeur",
          Observation: "pas mal",
        },
        {
          Date: "12123",
          Tâche: "Serge AHOUANSINOU",
          note_projet: { note_projet: 3 },
          note_tache: { note_tache: 4 },
          Superviseur: "Analyste programmeur",
          Observation: "cool",
        },
        {
          Date: "12123",
          Tâche: "Serge AHOUANSINOU",
          note_projet: { note_projet: 1 },
          note_tache: { note_tache: 2 },
          Superviseur: "Analyste programmeur",
          Observation: "Pas mal",
        },
      ],
      /* eslint-disable global-require */
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClass(item, type) {
      const superieur = "table-success text-success";
      const inferieur = "table-danger text-danger";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.total === "Accordée") {
        return colorAccorde;
      }
      if (item.total === "Refusée") {
        return colorRefuse;
      }
    },
  },
};
</script>
